// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matchup-js": () => import("./../../../src/pages/matchup.js" /* webpackChunkName: "component---src-pages-matchup-js" */),
  "component---src-pages-mint-js": () => import("./../../../src/pages/mint.js" /* webpackChunkName: "component---src-pages-mint-js" */),
  "component---src-pages-prizes-js": () => import("./../../../src/pages/prizes.js" /* webpackChunkName: "component---src-pages-prizes-js" */),
  "component---src-pages-seasons-js": () => import("./../../../src/pages/seasons.js" /* webpackChunkName: "component---src-pages-seasons-js" */),
  "component---src-pages-stake-js": () => import("./../../../src/pages/stake.js" /* webpackChunkName: "component---src-pages-stake-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */)
}

